/*****************************************************************************
 * UmanIT - Sticky header
 *****************************************************************************/

const headerBloc = document.querySelector("[data-layout='header']"),
      header = document.querySelector(".header"),
      pusher = document.querySelector(".pusher"),
      menusContainer = document.querySelector("[data-target='main-menu-container']"),
      banner = document.querySelector("[data-target='banner']")
      canHover = window.matchMedia('(hover: hover)').matches;
let menuPosTop,
    paddingTop;

if (pageYOffset === 0) {
  let menus = menusContainer.getBoundingClientRect();
  if(banner) {
    menuPosTop = menus.top - banner.offsetHeight;
  } else {
    menuPosTop = menus.top;
  }
} else {
  if(banner) {
    menuPosTop = 142 - banner.offsetHeight;
  } else {
    menuPosTop = 142;
  }
}

// Au chargement de la page, le padding pour le pusher
paddingTop = headerBloc.offsetHeight;


if(pageYOffset > headerBloc.offsetHeight) {
  headerBloc.classList.add("sticky", "sticky--half-way", "sticky--complete");
  if(banner) {
    headerBloc.style.paddingTop = `${banner.offsetHeight}px`;
    pusher.style.paddingTop = `${paddingTop + banner.offsetHeight}px`;
  } else {
    pusher.style.paddingTop = `${paddingTop}px`;
  }

}

window.addEventListener("scroll", () => {

  if(banner) {
    if(pageYOffset > 0) {
      banner.classList.add("sticky-banner");

      if (pageYOffset <= banner.offsetHeight) {
        if (breakpoints.isDesktop()) {
          headerBloc.style.paddingTop = `${banner.offsetHeight}px`;
        }
      }
    } else {
      headerBloc.style.paddingTop = 0;
      banner.classList.remove("sticky-banner");
    }
  }

    if((pageYOffset === 0) || (pageYOffset <= 0)) {
      menus = menusContainer.getBoundingClientRect();
      if(banner) {
        menuPosTop = menus.top - banner.offsetHeight;
      } else {
        menuPosTop = menus.top;
      }
      headerBloc.classList.remove("sticky", "sticky--half-way", "sticky--complete");
      pusher.style.paddingTop = 0;
    } else {
      toggleMenu(menuPosTop, paddingTop);
    }


});

window.addEventListener("resize", () => {
  toggleMenu(menuPosTop, paddingTop);
});

/**
 * Fonction qui masque ou affiche le menu en haut de page
 *
 * return {void}
 */
function toggleMenu(menuPosTop, paddingTop) {
  if (pusher.offsetHeight - window.innerHeight > paddingTop) {
    let scroll = pageYOffset;

    if(breakpoints.isDesktop() && canHover == true) {

      // 1. Si le scroll a dépassé les éléments de menu
      if(scroll > menuPosTop) {
        if(header.classList.contains("search-expanded")) {
          header.classList.remove("search-expanded");
        }
        pusher.style.paddingTop = `${paddingTop}px`;
        headerBloc.classList.add("sticky");
      } else {
        headerBloc.classList.remove("sticky");
        pusher.style.paddingTop = 0;
      }
      //pusher.style.paddingTop = `${paddingTop}px`;

      // 2. Si le scroll continue, on amorce l'animation
      if((headerBloc.classList.contains("sticky")) && (scroll > menuPosTop + 100)) {
        headerBloc.classList.add("sticky--half-way");
      } else if ((headerBloc.classList.contains("sticky")) && (scroll < menuPosTop + 100) && (scroll > menuPosTop )){
        headerBloc.classList.remove("sticky--half-way");
      }

      // 3. Le scroll se poursuit, on termine l'animation
      if((headerBloc.classList.contains("sticky")) && (scroll > menuPosTop + 200)) {
        headerBloc.classList.add("sticky--complete");
      } else if ((headerBloc.classList.contains("sticky")) && (scroll < menuPosTop + 2000) && (scroll > menuPosTop + 100 )){
        headerBloc.classList.remove("sticky--complete");
      }

    } else {
      // En mobile, pas besoin de la classe sticky
      headerBloc.classList.remove("sticky", "sticky--half-way", "sticky--complete");
      pusher.style.paddingTop = 0;
    }

  }
}
